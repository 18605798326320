import React, { useState } from "react";

interface IToken {
  token: string;
}

interface ICoordinates {
  latLng: [];
}

interface IEmployee {
  data: any;
}

type ContextType = {
  token: IToken;
  saveToken: (todo: IToken) => void;
  coordinates: ICoordinates;
  saveCoordinates: (data: ICoordinates) => void;
  employee: IEmployee;
  saveEmployee: (data: IEmployee) => void;
  getEmployee: any;
  saveClient: any;
  getClient: any;
};

export const AppContext = React.createContext<ContextType | null>(null);

const AppContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [token, setToken] = useState<IToken>({ token: "" });
  const [coordinates, setCoordinates] = useState<ICoordinates>({ latLng: [] });
  const [employee, setEmployee] = useState<IEmployee>({ data: {} });

  const saveToken = (todo: IToken) => {
    const newTodo: IToken = {
      token: todo.token,
    };
    setToken(newTodo);
    localStorage.setItem("token", todo.token);
  };

  const saveCoordinates = (data: ICoordinates) => {
    const coordinates: ICoordinates = {
      latLng: data.latLng,
    };
    setCoordinates(coordinates);
    localStorage.setItem("coordinates", JSON.stringify(data.latLng));
  };

  const saveEmployee = (data: IEmployee) => {
    const coordinates: IEmployee = data;
    setEmployee(coordinates);
    localStorage.setItem("employeeData", JSON.stringify(data));
  };

  const getEmployee = () => {
    return localStorage.getItem("employeeData");
  };

  const saveClient = (data: any) => {
    localStorage.setItem("clientData", JSON.stringify(data));
  };

  const getClient = () => {
    return localStorage.getItem("clientData");
  };

  return (
    <AppContext.Provider
      value={{
        token,
        saveToken,
        coordinates,
        saveCoordinates,
        employee,
        saveEmployee,
        getEmployee,
        saveClient,
        getClient,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
