import { useContext, useEffect, useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { getClient, getToken, login } from "../api/network";
import { AppContext } from "../context/AppContext";
import { toast } from "react-toastify";
import { URI } from "../api/network";

export const LoginView = () => {
  let history = useNavigate();
  const [userId, setUserId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [storeStatus, setStoreStatus] = useState(true);
  const [logo, setLogo] = useState("");

  const appContext = useContext(AppContext);

  const getSubdomain: any = () => {
    let host = window.location.host;
    let parts = host.split(".");
    let subdomain = "";
    // If we get more than 3 parts, then we have a subdomain
    // INFO: This could be 4, if you have a co.uk TLD or something like that.
    if (parts.length >= 3) {
      subdomain = parts[0];
      // Remove the subdomain from the parts list
      parts.splice(0, 1);
      // Set the location to the new url
      console.log("subdomain", subdomain);
      return subdomain;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { jwt } = await getToken();
      appContext?.saveToken({ token: jwt });
      localStorage.setItem("token",jwt )
      const {data} = await getClient(getSubdomain());
      // const {data} = await getClient('couto');
      if (data.length === 0) {
        setStoreStatus(false);
        showError("Wrong client");
        return false;
      } else if (data?.Active === false) {
        setStoreStatus(false);
        showError("Store Inactive");
        return false;
      }
      setStoreId(data[0].client.id);
      appContext?.saveClient({
        id: data[0].client.id,
        branch: data[0].client.Branch,
        email: data[0].client.email,
        logo: `${URI}${data[0].client?.logo?.url}`,
      });
      setLogo(`${URI}${data[0].client.logo.url}`);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const showError = (msg: string) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  };

  const goToHome = (data: any) => {
    if (!data.store.coordinates) {
      showError("Please verify the Geo Location");
      return false;
    }
    const coordinates =
      data.store.coordinates.features[0].geometry.coordinates;
    appContext?.saveCoordinates({ latLng: coordinates });
    history("/home");
  };

  const loginClick = async () => {
    if (!storeStatus) {
      showError("Need a valid client");
      return;
    }

    const {data} = await login(userId, storeId);
    if (data.length === 0) {
      showError("User id incorrect");
      return;
    }
    appContext?.saveEmployee(data[0]);

    if (data[0].active) {
      goToHome(data[0]);
    } else {
      showError("User not active");
    }
  };

  return (
    <div className="content w-container">
      <div className="container">
        <img src={logo} width="200" alt="" className="logo" />
        <div className="form-block w-form">
          <form id="email-form" name="email-form">
            <label htmlFor="name">User Id</label>
            <input
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              className="w-input"
              name="name"
              id="name"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <button
              onClick={loginClick}
              type="button"
              value="LOGIN"
              className="form_btn w-button"
            >
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
