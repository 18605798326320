import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { LoginView } from "./login/login";
import { HomeView } from "./home/home";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
        <Routes>
            <Route path="/" element={ <LoginView />} />
            <Route path="/home" element={<HomeView />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
