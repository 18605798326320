import React, { useEffect, useState, useContext } from "react";
import "./home.scss";
import ClockImg from "../assets/images/clock.svg";
import RefreshImg from "../assets/images/refresh.svg";
import PunchImg from "../assets/images/power-button.png";
import SendImg from "../assets/images/send.svg";
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import * as geolib from "geolib";
import { getPunches, savePunch } from "../api/network";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Puerto_Rico");

export const HomeView = () => {
  const webcamRef: any = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState();
  const [isCapture, setIsCapture] = React.useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState("");
  const [perimeterStatus, setPerimeterStatus] = useState("");
  const [punches, setPunches] = useState([]);
  const [clock, setClock] = useState("");
  const [isClockIn, setIsClockIn] = useState(true);

  const appContext = useContext(AppContext);
  const employeeData: any = JSON.parse(appContext?.getEmployee());
  const employeeName: any = `${employeeData?.first_name} ${employeeData?.last_name}`;
  const employeeId = employeeData.id;
  const clientData: any = JSON.parse(appContext?.getClient());
  const [logo] = useState(clientData.logo);

  useEffect(() => {
    getLocation();
    getPunchesData();
    // eslint-disable-next-line
  }, []);

  const clockInterval = () => {
    const now = new Date();
    const TwentyFourHour = now.getHours();
    let hour = now.getHours();
    let min: any = now.getMinutes();
    let mid = "pm";
    if (min < 10) {
      min = "0" + min;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour === 0) {
      hour = 12;
    }
    if (TwentyFourHour < 12) {
      mid = "am";
    }
    setClock(hour + ":" + min + mid);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      clockInterval();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setStatus("");

          const coordsArray: any = appContext?.coordinates.latLng;
          let coordJson: any = [];

          coordsArray.forEach((element: any) => {
            coordJson.push({
              latitude: element[1],
              longitude: element[0],
            });
          });

          const status = geolib.isPointInPolygon(
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            coordJson
          );
          setPerimeterStatus(status ? "Inside Perimeter" : "Out of Perimiter");

          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  };

  const getPunchesData = async () => {
    const {data} = await getPunches(employeeId);
    const punchesBackup = [...data];
    const lastPunch: any =
      punchesBackup.length === 1 ? data[0] : punchesBackup.pop();
    if (lastPunch) {
      setIsClockIn(lastPunch.section === "ClockIn" ? false : true);
    }
    setPunches(data);
  };

  const videoConstraints = {
    facingMode: "user",
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setIsCapture(true);
  }, [webcamRef]);

  const reCapture = () => {
    setIsCapture(false);
  };

  const imgData = (dataImg: any) => {
    const reg = /^data:image\/([\w+]+);base64,([\s\S]+)/;
    const match = dataImg.match(reg);
    const baseType: any = {
      jpeg: "jpg",
    };

    baseType["svg+xml"] = "svg";

    if (!match) {
      throw new Error("image base64 data error");
    }

    const extname = baseType[match[1]] ? baseType[match[1]] : match[1];

    return {
      extname: "." + extname,
      base64: match[2],
    };
  };

  const enviarClick = async () => {
    const time = `${dayjs().format()}Z`;

    const clockStatus = isClockIn ? "ClockIn" : "ClockOut";
    setIsClockIn(!isClockIn);

    await savePunch({
      employee: {
        id: employeeId,
      },
      section: clockStatus,
      date: time,
      lat: lat,
      lng: lng,
      img: imgData(imgSrc).base64,
    });
    getPunchesData();
    setIsCapture(false);
  };

  return (
    <div className="container w-container">
      <div className="content_home">
        <img src={logo} width="200" alt=""></img>
        <div className="greeting_title">Buenos Dias, {employeeName}</div>
        <img src={ClockImg} width="35" alt="Clock" className="clock_img"></img>
        {!isCapture && (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            className="webcam"
            screenshotQuality={0.3}
          />
        )}
        {isCapture && <img src={imgSrc} alt="capture" className="webcam" />}
        <div className="hour_label">{clock}</div>
        <p>{status}</p>
        {lat && <div className="lat_label">Latitude: {lat}</div>}
        {lng && <div className="lng_label">Longitude: {lng}</div>}
        <div className="lng_label">{perimeterStatus}</div>

        <div className="punchesTitle">Punches</div>

        {punches.map((value: any, index) => {
          return (
            <div key={index}>
              {value.section}: {value.date}
            </div>
          );
        })}

        {isCapture && (
          <div className="btn_content">
            <div className="btn_containr" onClick={reCapture}>
              <img src={RefreshImg} width="35" alt="" />
              <div className="btn_title">Retake</div>
            </div>
            <div className="btn_containr" onClick={() => enviarClick()}>
              <img src={SendImg} width="35" alt="" />
              <div className="btn_title">Submit</div>
            </div>
          </div>
        )}
        {!isCapture && (
          <div className="btn_content align-center">
            <div className="btn_containr " onClick={capture}>
              <img src={PunchImg} width="35" alt="" />
              <div className="btn_title">Punch</div>
            </div>
          </div>
        )}
        <Link className="salir_btn w-button" to="/">
          Logout
        </Link>
      </div>
    </div>
  );
};
