import axios from "axios";
import dayjs from "dayjs";

export const URI = "https://api.mopunch.com";
const startDate = dayjs().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ[Z]");
const endDate = dayjs().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ[Z]");


axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json"
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const api = axios.create({
  baseURL: URI,

});

export const getToken = async () => {

  const { data } = await api.post(`${URI}/api/auth/local`, {
    identifier: "cgonzalez@creativexit.net",
    password: "CGF09@qazx",
  });
  return data;
};

export const login = async (userId: string, storeId: string) => {
  const { data } = await api.get(
    `${URI}/api/employees?filters[user_id][$eq]=${userId}&filters[client][id][$eq]=${storeId}&populate=*`
  );
  return data;
};

export const getPunches = async (userId: number) => {
  console.log('Start Date', startDate)
  console.log('End Date', endDate)
  const { data } = await api.get(
    `${URI}/api/punches?filters[employee][id][$eq]=${userId}&filters[date][$gte]=${startDate}&filters[date][$lte]=${endDate}`
  );
  return data;
};

export const savePunch = async (info: any) => {
  const { data } = await api.post(`${URI}/api/punches`, {
    data: info
  });
  return data;
};

export const getClient = async (name: string) => {
  const { data } = await api.get(`${URI}/api/stores?client.username=${name}&populate=client.logo`);
  return data;
};
